<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="60%"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-divider></el-divider>
      <el-table
        :data="tableData"
        style="min-height: 400px"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label width="50"></el-table-column>
        <el-table-column
          prop="keyPersonId"
          label="家长编号"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="keyPerson.faceImageUrl"
          label="人员照片"
          width="80"
        >
          <template slot-scope="{ row }">
            <a :href="row.keyPerson.faceImageUrl" target="_blank">
              <el-avatar
                :size="48"
                shape="circle"
                :src="
                  row.keyPerson.faceImageUrl +
                  '?x-oss-process=image/resize,m_fill,w_64,h_64'
                "
                :key="row.id"
              ></el-avatar>
            </a>
          </template>
        </el-table-column>
        <el-table-column
          prop="relationship"
          label="关系"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="keyPerson.name"
          label="姓名"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="keyPerson.phone"
          label="手机号"
          width="180"
        ></el-table-column>
        <el-table-column label="操作" width="300" fixed="right">
          <template slot-scope="{ row }">
            <el-row>
              <el-col>
                <el-button
                  type="primary"
                  size="small"
                  plain
                  icon="el-icon-refresh"
                  @click="dataSync(row)"
                  >数据同步</el-button
                >
                <el-button size="mini" type="danger" @click="handleDelete(row)"
                  >删除</el-button
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import personRelationshipApi from "@/api/student/personRelationship";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["studentId", "title"],
  data() {
    var self = this;

    return {
      queryModel: {
        keyPersonId: "",
        associatedPersonId: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("associatedPersonId", self.studentId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      personRelationshipApi
        .familyList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;
          self.tableData = jsonData;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          personRelationshipApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    dataSync(record) {
      //批量同步人脸
      var self = this;

      var id = record.id;

      var formData = new FormData();

      formData.append("id", record.id);

      this.$confirm("是否确认同步选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          self.loading = true;
          personRelationshipApi.dataSyncParent(formData).then(function(response) {
            var jsonData = response.data;
            self.loading = false;
            if (jsonData.result) {
              if (jsonData.data) {
                self.changePage(self.pageIndex);
                self.$message({
                  type: "success",
                  message: "同步成功!"
                });
              } else {
                if (jsonData.message != null) {
                  //下载有错误信息提示的报表
                  //window.open(response.data);
                  self.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message:
                      "错误" +
                      `,<a href="${jsonData.message}" target="_blank">点击下载错误报表</a>&nbsp;`,
                    duration: 30000
                  });
                }
              }
            } else {
              self.$message({
                type: "warning",
                message: jsonData.message
              });
            }
          });
        })
        .catch(() => {
          self.loading = false;
        });
    },
  },
  mounted: function () {
    this.changePage(1);
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>