import request from '@/utils/request'
import constant from '@/constant'
import { getToken } from "@/utils/auth"; // get token from cookie

function updateExamineStudentById(formData) {
  return request.post(constant.serverUrl + "/base/studentInfo/updateExamineStudentById", formData);
}

function studentUpdate(formModel) {
  return request.post(constant.serverUrl + "/base/personInfo/studentUpdate", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}


function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/base/studentInfo/exportXls", formData);
}

export default {
  updateExamineStudentById,studentUpdate,exportXls
}