import request from '@/utils/request'
import constant from '@/constant'
import { getToken } from "@/utils/auth"; // get token from cookie

function updateExamineStudentById(formData) {
  return request.post(constant.serverUrl + "/mobile/teacherInfoApi/updateExamineStudentById", formData);
}

export default {
  updateExamineStudentById
}